import React from 'react'

export const Footer = () => (
  <footer className="bg-primary-dark">
    <div className="flex justify-center bg-primary-dark">
      <a href="https://linkedin.com/in/karanaditya993" className="text-tertiary-normal hover:text-gray-800 m-2" target="_blank">
        <span className="icon  icon--linkedin">
        <svg style={{'width':'24px', 'height':'24px'}} viewBox="0 50 512 512" >
          <path fill="currentColor" d="M150.65,100.682c0,27.992-22.508,50.683-50.273,50.683c-27.765,0-50.273-22.691-50.273-50.683
          C50.104,72.691,72.612,50,100.377,50C128.143,50,150.65,72.691,150.65,100.682z M143.294,187.333H58.277V462h85.017V187.333z
          M279.195,187.333h-81.541V462h81.541c0,0,0-101.877,0-144.181c0-38.624,17.779-61.615,51.807-61.615
          c31.268,0,46.289,22.071,46.289,61.615c0,39.545,0,144.181,0,144.181h84.605c0,0,0-100.344,0-173.915
          s-41.689-109.131-99.934-109.131s-82.768,45.369-82.768,45.369V187.333z"/>
        </svg>
        </span>
      </a>
      <a href="https://github.com/karanaditya993" className="text-tertiary-normal hover:text-gray-800 m-2" target="_blank">
        <svg style={{'width':'24px', 'height':'24px'}} viewBox="0 0 24 24">
          <path fill="currentColor" d="M12,2A10,10 0 0,0 2,12C2,16.42 4.87,20.17 8.84,21.5C9.34,21.58 9.5,21.27 9.5,21C9.5,20.77 9.5,20.14 9.5,19.31C6.73,19.91 6.14,17.97 6.14,17.97C5.68,16.81 5.03,16.5 5.03,16.5C4.12,15.88 5.1,15.9 5.1,15.9C6.1,15.97 6.63,16.93 6.63,16.93C7.5,18.45 8.97,18 9.54,17.76C9.63,17.11 9.89,16.67 10.17,16.42C7.95,16.17 5.62,15.31 5.62,11.5C5.62,10.39 6,9.5 6.65,8.79C6.55,8.54 6.2,7.5 6.75,6.15C6.75,6.15 7.59,5.88 9.5,7.17C10.29,6.95 11.15,6.84 12,6.84C12.85,6.84 13.71,6.95 14.5,7.17C16.41,5.88 17.25,6.15 17.25,6.15C17.8,7.5 17.45,8.54 17.35,8.79C18,9.5 18.38,10.39 18.38,11.5C18.38,15.32 16.04,16.16 13.81,16.41C14.17,16.72 14.5,17.33 14.5,18.26C14.5,19.6 14.5,20.68 14.5,21C14.5,21.27 14.66,21.59 15.17,21.5C19.14,20.16 22,16.42 22,12A10,10 0 0,0 12,2Z"
          />
        </svg>
      </a>
    </div>
    <div className="flex text-primary-light justify-center bg-primary-dark mt-2">
      <div>© Karan Aditya</div>
    </div>
  </footer>
)
